import React, { useState } from "react";
import InputField from "../InputField/InputField";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import styles from "./Login.module.css";

function Login(props) {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    pass: "",
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const handleSubmission = () => {
    if (!values.email || !values.pass) {
      setErrorMsg("Fill all fields");
      return;
    }
    setErrorMsg("");

    setSubmitButtonDisabled(true);
    signInWithEmailAndPassword(auth, values.email, values.pass)
      .then(async (res) => {
        setSubmitButtonDisabled(false);

        navigate("/");
      })
      .catch((err) => {
        setSubmitButtonDisabled(false);
        setErrorMsg(err.message);
      });
  };
  return (
    <div className={styles.container}>
      {!props.status && (
        <div className={styles.innerBox}>
          <h1 className={styles.heading}>Login</h1>

          <InputField
            label="Email"
            type="Text"
            onChange={(event) =>
              setValues((prev) => ({ ...prev, email: event.target.value }))
            }
            placeholder="Enter email address"
          />

          <InputField
            label="Password"
            type="Password"
            onChange={(event) =>
              setValues((prev) => ({ ...prev, pass: event.target.value }))
            }
            placeholder="Enter Password"
          />

          <div className={styles.footer}>
            <b className={styles.error}>{errorMsg}</b>
            <button disabled={submitButtonDisabled} onClick={handleSubmission}>
              Login
            </button>
            <p>
              Create Free Account?{" "}
              <span>
                <Link to="/signup">Sign up</Link>
              </span>
            </p>
          </div>
        </div>
      )}

      {props.status && (
        <div className={styles.innerBox}>
          <h1 className={styles.heading} styles={{ alignItems: "center" }}>
            Already Login
          </h1>
          <div className={styles.footer}>
            <button>
              <span>
                <Link to="/">Home</Link>
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
