import React, { useState } from "react";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";

function AddData(props) {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const addData = async () => {
    try {
      const docRef = await addDoc(
        collection(db, `notes`, props.user.uid, `my_notes`),
        {
          title: title,
          content: content,
          timestamp: serverTimestamp(),
          //   { seconds: 1692549201, nanoseconds: 292000000 },
        }
      );
      setTitle("");
      setContent("");

      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <div style={{ background: "rgba(236, 236, 236, 0.87)", height: "100vh" }}>
      <h1>{props.user.uid}</h1>
      <div class="container" style={{ paddingTop: "50px" }}>
        <div class="card">
          <div class="card-header card-title">Add Data</div>
          <div>
            <form style={{ padding: "10px" }}>
              <div class="form-group">
                <label for="exampleFormControlInput1">Title</label>
                <input
                  type="text"
                  class="form-control"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    console.log("Title: ", title);
                  }}
                />
              </div>
              <div class="form-group">
                <label for="exampleFormControlTextarea1">Content</label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  value={content}
                  onChange={(txt) => {
                    setContent(txt.target.value);
                    console.log("Content: ", content);
                  }}
                />
              </div>
            </form>
          </div>
          <button onClick={addData} type="submit">
            add data
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddData;
