import React from "react";
import { Link } from "react-router-dom";

function Unknown() {
  return (
    <div>
      <h2>
        <Link to="/login">Plz login</Link>
      </h2>
    </div>
  );
}

export default Unknown;
