import React, { useState } from 'react'
import InputField from '../InputField/InputField'
import { createUserWithEmailAndPassword , updateProfile } from 'firebase/auth';
import { auth } from '../../firebase';
import { Link, useNavigate } from 'react-router-dom';
import styles from "./Signup.module.css";


function Signin(props) {
    const navigate=useNavigate();
    const [value, setValue]=useState({
        name:"",
        email:"",
        password:""

    });
    const [errorMsg, setErrorMsg] = useState("");
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    const submit=()=>{
        console.log(value)
        if(!value.email || !value.name || !value.password){
            setErrorMsg("Fill all fields")
            return;
        }
        setErrorMsg("");
        setSubmitButtonDisabled(true);
        createUserWithEmailAndPassword(auth, value.email, value.password)
        .then(async (res)=>{
            console.log(res);
            setSubmitButtonDisabled(false);
            const user =res.user;
            await updateProfile(user, {
                displayName: value.name,
            });
            navigate('/')
        })
        .catch((err)=>{
            console.log("Error: ",err)
            setSubmitButtonDisabled(false);
            setErrorMsg(err.message);
        });
    };
  return (
    <div className={styles.container}>
      {!props.status && <div className={styles.innerBox}>
        <h1 className={styles.heading}>Signup</h1>
        
        <InputField 
            label="Name" 
            type="Text" 
            placeholder="Enter your name" 
            onChange={
                (event)=>setValue((prev)=>({...prev, name:event.target.value}))
        }/>

        <InputField 
            label="Email" 
            type="Email" 
            placeholder="Enter email address"
            onChange={
                (event)=>setValue((prev)=>({...prev, email:event.target.value}))
        }/>

        <InputField 
            label="Password" 
            type="Password" 
            placeholder="Enter your password"
            onChange={
                (event)=>setValue((prev)=>({...prev, password:event.target.value}))
        }/>

        <div className={styles.footer}>
            <b className={styles.error}> {errorMsg}</b>
            <button onClick={submit} disabled={submitButtonDisabled}>Sign Up</button>
            <p>
                Already have an account?{" "}
                <span>
                    <Link to="/login">Login </Link>
                </span>
            </p>
        </div> 
    </div>}
    {props.status && <div className={styles.innerBox}>
            <h1 className={styles.heading} styles={{alignItems: "center"}}>Already Login</h1>
            <button><Link to="/">Home</Link></button>
        </div>

        }
    </div>
 
  );
}

export default Signin
