import React from 'react'
import styles from "./InputControl.module.css";


function InputField(props) {
  return (
    <div className={styles.container}>
        {props.label && <label>{props.label}</label>}
        <input {...props}/>
    </div>
  )
}

export default InputField
