import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Signin from "./components/Signin/Signin";
import Login from "./components/Login/Login";
import { app, auth, db } from "./firebase";

import { collection, getDocs } from "firebase/firestore";
import AddData from "./components/AddData";

function App() {
  const [data, setData] = useState([]);
  const [user, setUser] = useState(null);
  const [isUser, setIsUser] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDetails = auth.onAuthStateChanged((user) => {
          if (user) {
            setIsUser(true);
            setUser(user);
            const conlRef = collection(db, `notes`, user.uid, `my_notes`);
            getDocs(conlRef).then((snapshot) => {
              const fetchedData = [];
              snapshot.forEach((doc) => {
                fetchedData.push(doc.data());
                console.log(
                  "***************************************\n",
                  doc.docs
                );
              });
              setData(fetchedData);
            });
          } else {
            setUser(null);
            setIsUser(false);
          }
        });
        userDetails();
      } catch (error) {
        console.error("error msg: ", error);
      }
    };
    fetchData();
  }, []);

  //*************************************************************************** */

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={<Home status={isUser} user={user} data={data} />}
          />
          <Route path="/signup" element={<Signin status={isUser} />} />
          <Route path="/login" element={<Login status={isUser} />} />
          <Route
            path="/data"
            element={<AddData status={isUser} user={user} />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

// referal Link: https://www.youtube.com/watch?v=wj94hKFgXyk&ab_channel=HandyDev
//https://github.com/handyDev2/firebase-auth
