import React from "react";
import logo from "./logo.png";
import { auth } from "../../firebase";

function Header() {
  var user = auth.currentUser;

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      // After successful sign-out, you can redirect the user or perform other actions as needed.
      // For example, you can redirect them to a login page.
      window.location.reload();
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };
  return (
    <div>
      <nav class="navbar" style={{ backgroundColor: "rgb(155, 155, 155)" }}>
        <div class="container-fluid">
          <a class="navbar-brand" href="#">
            <img
              src={logo}
              alt="Logo"
              width="30"
              height="30"
              class="d-inline-block align-text-top"
            />
          </a>
          <p>{user.email}</p>
          <button
            style={{
              backgroundColor: "white",
              border: "1px solid black",
              borderRadius: "5px",
              paddingLeft: "10px",
              paddingRight: "10px",
              //   paddingLeft: "5px",
              //   paddingLeft: "5px",
            }}
            onClick={handleSignOut}
          >
            Logout
          </button>
        </div>
      </nav>
    </div>
  );
}

export default Header;
