import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Unknown from "../Unknown/Unknown";
import { Link } from "react-router-dom";
import "./Home.css";
import { auth } from "../../firebase";
import AddData from "../AddData";

// import { getDocs } from "firebase/firestore";

function Home(props) {
  const [addData, setaddData] = useState(false);
  const [datetime, setDatetime] = useState("");

  const formattedDatetime = (sec) => {
    const date = new Date(sec * 1000);
    return date.toLocaleString();
  };

  return (
    <>
      {props.user && (
        <div>
          <div>
            <Header />
          </div>
          {!addData && (
            <div className="outer-body ">
              {/* <h1>{props.user.uid}</h1> */}

              <div class="container">
                <div class="row">
                  {props.data.map((item, index) => (
                    <div key={index}>
                      {/* <div>{JSON.stringify(item)}</div> */}
                      <div
                        class="card"
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        <div class="card-header card-title ">
                          <div>
                            <h4>{item.title}</h4>
                          </div>
                        </div>

                        <div class="card-body">
                          <p class="card-text">{item.content}</p>

                          <p style={{ fontSize: "12px", float: "right" }}>
                            {formattedDatetime(item.timestamp.seconds)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <button
                className="leftbutton btn btn-light"
                onClick={() => setaddData(true)}
              >
                +
              </button>
            </div>
          )}
        </div>
      )}
      {addData && (
        <div>
          <AddData user={props.user} />
          <button
            className="leftbutton btn btn-light"
            onClick={() => setaddData(false)}
          >
            -
          </button>
        </div>
      )}
      {!props.user && (
        <div>
          <Unknown />
        </div>
      )}
    </>
  );
}

export default Home;
